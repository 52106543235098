import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import "./indexxf.less";
import { baseUrl1, baseUrl2 } from "../../../../config";
import axios from "axios";
import { message } from "antd";
import moment from "moment";

// new 消费记录弹窗
const ConsumeModal = React.forwardRef((props, ref) => {
  const userId = useSelector((state) => state.User.userId);
  const [visible, setVisible] = React.useState(false);
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const loadMoreData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data: res } = await axios({
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          user_id: userId,
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/userConsumeRecord`,
      });
      if (res.status === 10000) {
        setData(res?.data);
      } else {
        message.message(res?.message);
      }
    } catch (error) {
      message.message("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible && userId) loadMoreData();
    else setData([]);
  }, [visible, userId]);

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onCancel={close}
      className="ap_modal_consume"
    >
      <div className="ap_modal2">
        <div className="ap_modal_boxf">
          {/* <div
            className="ap_modal_header2"
            style={{
              backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
            }}
          >
            <span className="title">消费记录</span>
          </div> */}
          <div className="ap_modal_body2">
            <img
              className="close_img"
              src={require("../../../../assets/Sushiimg/close.png")}
              alt=""
              onClick={() => {
                setVisible(false);
              }}
            />
            <div className="consume">
              {/* <div className="consume_th">
                <div className="item">花费</div>
                <div className="item">奖励</div>
              </div> */}
              <ul className="consume_list">
                {data &&
                  data?.length > 0 &&
                  data.map((item) => (
                    <li className="consume_box" key={"consume_" + item?.id}>
                      <div className="consume_item">
                        <div className="top">
                          <div className="value">
                            <img
                              className="icon"
                              src={require("../../../../assets/Sushiimg/shousi1.png")}
                              alt=""
                            />
                            <span>x{item?.pay_amount}</span>
                          </div>
                          <span
                            className={`status ${
                              item?.status === 1 ? "success" : ""
                            }`}
                          >
                            {item?.status === 1 ? "准备成功" : "准备失败"}
                          </span>
                        </div>
                        <div className="content">
                          <div className="row">
                            {item?.dinosaur_paradise?.dinosaur_info.name ===
                              "Boss" ||
                            item?.dinosaur_paradise?.dinosaur1_info.name ===
                              "Boss" ? (
                              <span className="label">老爹精选</span>
                            ) : (
                              <span className="label">本轮中奖的寿司</span>
                            )}

                            <span className="label">准备的寿司</span>
                          </div>
                          <div className="row2 margin_top4">
                            <div className="info_img">
                              {/* {item?.dinosaur_info.map((infoImg) => (
                                <img src={infoImg.icon} alt="" />
                              ))} */}
                              <img
                                src={
                                  item?.dinosaur_paradise?.dinosaur1_info.icon
                                }
                                alt=""
                              />
                              <img
                                src={
                                  item?.dinosaur_paradise?.dinosaur_info.icon
                                }
                                alt=""
                              />
                            </div>
                            {/* <img src={item?.dinosaur_info.icon} alt="" /> */}
                            <div className="img_list">
                              {item?.turret_list &&
                                item?.turret_list?.length > 0 &&
                                item?.turret_list.map((img1) => (
                                  <img
                                    key={"img_item_" + img1?.id}
                                    src={img1?.turret_info?.icon}
                                    alt=""
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <span className="label">
                            {moment(item?.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                {data.length === 0 && <div className="empty">暂无数据</div>}
              </ul>
            </div>
          </div>
        </div>
        <span className="label">仅展示近7天的数据哦～</span>
      </div>
    </Modal>
  );
});

export default ConsumeModal;
